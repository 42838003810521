// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css'; // Your custom sidebar styles

function Sidebar() {
  return (
    <div className="sidebar">
      {/* Add the logo and text at the top */}
      <div className="sidebar-header">
        <img src="/sidebar-logo.png" alt="Logo" className="sidebar-logo" />
        <h3 className="sidebar-title">KubeShe</h3> {/* Add your app's name */}
      </div>
      
      {/* Navigation Links */}
      <nav>
        <ul>
          <li>
            <Link to="/dashboard">
              <i className="fas fa-chart-line"></i> Dashboard
            </Link>
          </li>
          <li>
            <Link to="/jobs">
              <i className="fas fa-tasks"></i> Jobs
            </Link>
          </li>
          <li>
            <Link to="/logout">
              <i className="fas fa-sign-out-alt"></i> Logout
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
