// src/components/Dashboard.js
import React from 'react';

function Dashboard() {
  return (
    <div>
      <h1>Dashboard</h1>
      {/* Content for the dashboard can go here later */}
    </div>
  );
}

export default Dashboard;
