// src/components/Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Logout({ onLogout }) {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear token and update state
    Cookies.remove('token');
    onLogout(false);
    navigate('/login');  // Redirect to login page
  }, [onLogout, navigate]);

  return <h2>Logging out...</h2>;
}

export default Logout;
