import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Switch from 'react-switch'; // Import the Switch component
import Cookies from 'js-cookie';
import cronParser from 'cron-parser';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/yaml/yaml';
import yaml from 'js-yaml';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Jobs.css'; // Ensure this path is correct
import { FaSyncAlt } from 'react-icons/fa';

const API_HOST = process.env.REACT_APP_API_HOST;

function Jobs() {
  const [cronjobs, setCronjobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [selectedPodLog, setSelectedPodLog] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [cronjobsPerPage, setCronjobsPerPage] = useState(10); // Set default to 10
  const [editorVisible, setEditorVisible] = useState(false);
  const [yamlContent, setYamlContent] = useState('');
  const [namespaces, setNamespaces] = useState([]);
  const [selectedNamespace, setSelectedNamespace] = useState('');
  const [alertSettings, setAlertSettings] = useState({}); // State to manage alert toggles

  // Fetch namespaces on component mount
  useEffect(() => {
    fetchNamespaces();
  }, []);

  // Fetch cronjobs when the selected namespace or pagination changes
  useEffect(() => {
    fetchCronjobs();
  }, [selectedNamespace, currentPage, cronjobsPerPage]);

  // Handle refresh button click
  const handleRefresh = () => {
    fetchCronjobs(); // Refresh cronjobs
    setSelectedPodLog(null); // Clear logs when refresh is clicked
  };

  // Auto-refresh logic
  useEffect(() => {
    let intervalId = null;
    if (autoRefresh) {
      intervalId = setInterval(() => {
        fetchCronjobs();
        setSelectedPodLog(null); // Clear logs when auto-refresh triggers
      }, 15000); // 15 seconds interval
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [autoRefresh, selectedNamespace]);

  // Fetch namespaces from the backend
  const fetchNamespaces = () => {
    setLoading(true);
    axios
      .get(`${API_HOST}/api/namespaces`, {
        headers: { 'x-access-token': Cookies.get('token') },
      })
      .then((response) => {
        setNamespaces(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching namespaces:', error);
        setLoading(false);
      });
  };

  // Fetch cronjobs for the selected namespace
  const fetchCronjobs = () => {
    setLoading(true);

    let url = `${API_HOST}/api/cronjobs`;
    url += selectedNamespace ? `?namespace=${selectedNamespace}` : `?namespace=all`;

    axios
      .get(url, {
        headers: { 'x-access-token': Cookies.get('token') },
      })
      .then((response) => {
        setCronjobs(response.data); // Update cronjobs state
        setLoading(false); // Stop loading
      })
      .catch((error) => {
        console.error('Error fetching cronjobs:', error);
        setLoading(false); // Stop loading on error
      });
  };

  // Fetch pod logs
  const fetchPodLogs = (podName) => {
    setLoading(true);

    const namespaceToUse = selectedNamespace === "all" || selectedNamespace === "" ? "all" : selectedNamespace;

    axios
      .get(`${API_HOST}/api/podlogs/${podName}`, {
        headers: { 'x-access-token': Cookies.get('token') },
        params: { namespace: namespaceToUse },
      })
      .then((response) => {
        setSelectedPodLog(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching pod logs:', error);
        alert('An error occurred while fetching logs.');
        setLoading(false);
      });
  };

  // Handle alert toggle
  const handleAlertToggle = (cronjobName) => {
    setAlertSettings((prevSettings) => ({
      ...prevSettings,
      [cronjobName]: !prevSettings[cronjobName],
    }));
  };

  // Status color logic
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Succeeded':
        return { color: 'green', fontWeight: 'bold' };
      case 'Failed':
        return { color: 'red', fontWeight: 'bold' };
      case 'Pending':
        return { color: 'orange', fontWeight: 'bold' };
      default:
        return { color: 'black' };
    }
  };

  // Toggle YAML editor visibility
  const toggleEditor = () => {
    setEditorVisible(!editorVisible);
  };

  // Cancel YAML Editor
  const handleCancelYaml = () => {
    setYamlContent(''); // Clear the YAML content
    setEditorVisible(false); // Hide the editor
  };

  // Submit YAML content to create a new CronJob
  const handleYamlSubmit = async () => {
    setLoading(true);
    try {
      if (!yamlContent) {
        Swal.fire({
          icon: 'error',
          title: 'YAML Error',
          text: 'YAML content cannot be empty.',
        });
        setLoading(false);
        return;
      }

      const parsedYaml = yaml.load(yamlContent);
      const metadataLabels = parsedYaml?.metadata?.labels;
      const podLabels = parsedYaml?.spec?.jobTemplate?.spec?.template?.metadata?.labels;

      if (!metadataLabels || !podLabels) {
        Swal.fire({
          icon: 'error',
          title: 'Missing Labels',
          text: "The YAML configuration is missing necessary 'labels' in the metadata or pod template.",
        });
        setLoading(false);
        return;
      }

      await axios.post(
        `${API_HOST}/api/upload-cronjob`,
        { yaml: yamlContent },
        {
          headers: {
            'x-access-token': Cookies.get('token'),
            'Content-Type': 'application/json',
          },
        }
      );

      fetchCronjobs(); // Refresh cronjob list
      setYamlContent(''); // Reset the YAML editor
      setEditorVisible(false); // Hide editor on success
      Swal.fire({
        icon: 'success',
        title: 'CronJob Created',
        text: 'CronJob created successfully!',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'YAML Error',
        text: 'Failed to create CronJob. Please check the YAML configuration.',
      });
      setLoading(false);
    }
  };

  // Calculate last run time of the cron job
  const calculateLastRunTime = (cronSchedule) => {
    try {
      const interval = cronParser.parseExpression(cronSchedule);
      const lastRun = interval.prev().toDate();
      return lastRun.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (err) {
      console.error('Error calculating last run time:', err);
      return 'Invalid schedule';
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(cronjobs.length / cronjobsPerPage);
  const indexOfLastCronjob = currentPage * cronjobsPerPage;
  const indexOfFirstCronjob = indexOfLastCronjob - cronjobsPerPage;
  const currentCronjobs = cronjobs.slice(indexOfFirstCronjob, indexOfLastCronjob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle pod name search
  const filteredCronjobs = currentCronjobs.filter((cronjob) =>
    cronjob.pods.some((pod) => pod.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="container mt-5">
      <div className="d-flex align-items-center mb-4">
        <h1 className="jobs-title">Jobs</h1>
        <button className="refresh-button ms-3" onClick={handleRefresh}>
          <FaSyncAlt />
        </button>
        <button className="btn create-jobs-btn ms-auto" onClick={toggleEditor}>
          <i className="fas fa-plus"></i> Create Jobs
        </button>
      </div>

      {/* Filters section */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <i className="fas fa-filter me-2"></i>
          <select
            className="form-select form-select-sm"
            value={selectedNamespace}
            onChange={(e) => setSelectedNamespace(e.target.value)}
          >
            <option value="">All Namespaces</option>
            {namespaces.map((namespace) => (
              <option key={namespace} value={namespace}>
                {namespace}
              </option>
            ))}
          </select>
        </div>

        <div>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search by Pod name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Table */}
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th>CronJob Name</th>
              <th>Schedule</th>
              <th>Last Schedule Time</th>
              <th>Pod Name</th>
              <th>Status</th>
              <th>Action</th>
              <th>Alerts</th> {/* New column for Alerts */}
            </tr>
          </thead>
          <tbody>
            {filteredCronjobs.map((cronjob) =>
              cronjob.pods.slice(-3).map((pod, index) => (
                <tr key={index}>
                  {index === 0 && (
                    <>
                      <td rowSpan={cronjob.pods.length}>{cronjob.name}</td>
                      <td rowSpan={cronjob.pods.length}>{cronjob.schedule}</td>
                      <td rowSpan={cronjob.pods.length}>{calculateLastRunTime(cronjob.schedule)}</td>
                    </>
                  )}
                  <td>{pod.name}</td>
                  <td style={getStatusStyle(pod.status)}>{pod.status}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => fetchPodLogs(pod.name)}
                    >
                      View Logs
                    </button>
                  </td>
                  {index === 0 && (
                    <td rowSpan={cronjob.pods.length}>
                      <div className="alert-toggle-container">
                        <i className="fas fa-bell"></i>
                        <Switch
                          checked={alertSettings[cronjob.name] || false}
                          onChange={() => handleAlertToggle(cronjob.name)}
                          onColor="#00d084"
                          offColor="#ccc"
                          checkedIcon={<div style={{ color: 'white', paddingLeft: 20, fontSize: '12px' }}>On</div>}  // Added more padding
                          uncheckedIcon={<div style={{ color: 'black', paddingLeft: 20, fontSize: '12px' }}>Off</div>}  // Added more padding
                        />
                      </div>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <nav className="d-flex justify-content-end mt-4">
        <ul className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
              <button onClick={() => paginate(index + 1)} className="page-link">
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {/* YAML Editor */}
      {editorVisible && (
        <div className="editor-container mt-5">
          <CodeMirror
            value={yamlContent}
            options={{
              mode: 'yaml',
              theme: 'material',
              lineNumbers: true,
            }}
            onBeforeChange={(editor, data, value) => {
              setYamlContent(value);
            }}
          />
          <div className="mt-3 d-flex">
            <button className="btn btn-success" onClick={handleYamlSubmit} disabled={loading}>
              {loading ? 'Submitting...' : 'Submit YAML'}
            </button>
            <button className="btn btn-danger ms-2" onClick={handleCancelYaml} disabled={loading}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {selectedPodLog && (
        <div className="mt-5">
          <h3>Logs for Pod: {selectedPodLog.pod_name}</h3>
          <pre className="bg-light p-3">{selectedPodLog.log}</pre>
        </div>
      )}
    </div>
  );
}

export default Jobs;
