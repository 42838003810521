import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for redirection
import './Login.css'; // Ensure this path is correct

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Sending login request...");
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { username, password });
      console.log("Received response:", response.data);
      Cookies.set('token', response.data.token);  // Save token in cookies
      onLogin(true);  // Update the parent component about authentication

      // Redirect the user to the dashboard after successful login
      navigate('/dashboard');
    } catch (err) {
      if (err.response && err.response.status === 403) {
        // Display the error message to the user
        setError(err.response.data.message);
      } else {
        setError('Invalid credentials. Please try again.');
      }
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <img src="/kubernetes-logo.png" alt="Kubernetes Logo" className="login-logo" />
        <h2>Log in to your account</h2>
        <p>Please enter your details</p>
        {error && <p className="error-message">{error}</p>}
        <div className="input-group">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
}

export default Login;
