import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import Jobs from './components/Jobs';
import Login from './components/Login';
import Logout from './components/Logout'; 
import Cookies from 'js-cookie';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('token'));

  // Automatically check for token presence when the app starts
  useEffect(() => {
    const token = Cookies.get('token');
    setIsAuthenticated(!!token);
  }, []);

  // Ensure user is redirected to login if not authenticated
  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="app-container">
        {/* Render Sidebar only when the user is authenticated */}
        {isAuthenticated && <Sidebar />} 
        
        {/* Main content area */}
        <div className={isAuthenticated ? "content-container" : "full-width"}>
          <Routes>
            {/* Login route */}
            <Route path="/login" element={<Login onLogin={setIsAuthenticated} />} />
            
            {/* Protected routes */}
            <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
            <Route path="/jobs" element={<PrivateRoute element={<Jobs />} />} />
            <Route path="/logout" element={<PrivateRoute element={<Logout onLogout={setIsAuthenticated} />} />} />

            {/* Default route: if authenticated, go to dashboard, otherwise login */}
            <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
